import { Avatar, List, Typography, Stack, Menu, Button, MenuItem } from '@mui/material';

import { MenuContainer } from './style';
import Link from 'next/link';
import { useContext, useState } from 'react';
import useNotification from 'src/hooks/useNotification';
import { Logo } from 'src/components/svgs/Logo';
import { renderMenuItem } from '..';
import { navigationItems } from 'src/@data/navigation';
import { ROLE_PERMISSIONS } from 'src/middleware';
import { UserContext } from 'src/contexts/user';
import { ROLE, ROLES_TYPES } from 'src/utils/enums';
import ScrollContainer from 'src/components/@admin/ScrollContainer';
import { ExpandMore } from '@mui/icons-material';
import { useRouter } from 'next/navigation';
import { Role } from 'src/graphql/generated/graphql';

export default function NavBar({ notificationItems, currentPage, role }) {
   const [_msg, sendNotification] = useNotification();
   const { user, userRoles, handleChangeRole } = useContext(UserContext);
   const router = useRouter();

   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   return (
      <MenuContainer>
         <Stack alignItems="center" width="100%">
            <Link href="/resumo">
               <Logo />
            </Link>
            <Stack
               // sx={{ borderRadius: "0.5rem", background: "white", padding: "0 0.5rem"}}
               sx={{ marginTop: '1.25rem', width: '100%' }}
            >
               <ScrollContainer
                  style={{
                     height: 'calc(100vh - 300px)',
                  }}
               >
                  <List>
                     {navigationItems
                        .filter((item) => ROLE_PERMISSIONS[item.path].includes(role))
                        .map((item) => renderMenuItem({ item, currentPage }))}
                  </List>
               </ScrollContainer>
            </Stack>
         </Stack>

         <Stack alignSelf="flex-end" width="100%" gap={1}>
            <List>{notificationItems.map((item) => renderMenuItem({ item, currentPage }))}</List>

            {userRoles?.getUserRoles && userRoles?.getUserRoles.length > 0 ? (
               <Button
                  sx={{
                     padding: '0.3rem 0.35rem',
                     borderRadius: '0.5rem',
                     transition: '400ms',
                     border: '0.063rem solid transparent',
                     cursor: 'pointer',
                     '&:hover': { backgroundColor: '#E9F4FF', border: '0.063rem solid blue' },
                  }}
                  direction="row"
                  gap={1}
                  alignItems="center"
                  alignContent="center"
                  alignSelf="flex-end"
                  width="100%"
                  onClick={handleClick}
               >
                  <Avatar alt={user?.getUser?.fullName || ''} src="/static/images/avatar/1.jpg" />
                  <Stack direction="column" margin="0 0.5rem" flex={1} alignItems="flex-start">
                     <Typography
                        variant="h4"
                        sx={{
                           maxWidth: '180px',
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                           whiteSpace: 'nowrap',
                           color: 'black',
                        }}
                     >
                        {user?.getUser.fullName}
                     </Typography>
                     <Typography variant="subtitle2" color="#454754">
                        {user?.getUser.role ? ROLES_TYPES[user?.getUser.role] : ''}
                     </Typography>
                  </Stack>
                  <ExpandMore sx={{ fontSize: '1.5rem', color: '#74778b' }} />
               </Button>
            ) : (
               <Stack
                  direction="row"
                  gap={1}
                  alignItems="center"
                  alignContent="center"
                  alignSelf="flex-end"
                  width="100%"
               >
                  <Avatar alt={user?.getUser?.fullName || ''} src="/static/images/avatar/1.jpg" />
                  <Stack direction="column" margin="0 0.5rem" flex={1} alignItems="flex-start">
                     <Typography
                        variant="h4"
                        sx={{
                           maxWidth: '180px',
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                           whiteSpace: 'nowrap',
                           color: 'black',
                        }}
                     >
                        {user?.getUser.fullName}
                     </Typography>
                     <Typography variant="subtitle2" color="#454754">
                        {user?.getUser.role ? ROLES_TYPES[user?.getUser.role] : ''}
                     </Typography>
                  </Stack>
               </Stack>
            )}
            {userRoles?.getUserRoles && userRoles?.getUserRoles.length > 0 && (
               <Menu
                  anchorOrigin={{
                     vertical: 'top',
                     horizontal: 'left',
                  }}
                  transformOrigin={{
                     vertical: 'top',
                     horizontal: 'left',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
               >
                  {userRoles?.getUserRoles.map((role) => (
                     <MenuItem
                        sx={{ width: '14rem' }}
                        onClick={() => {
                           handleChangeRole(ROLE[role.role]);
                           handleClose();
                           router.push('/resumo');
                        }}
                     >
                        {ROLES_TYPES[ROLE[role.role]]}
                     </MenuItem>
                  ))}
                  {user?.getUser.fullName === 'Back Office' && (
                     <MenuItem
                        sx={{ width: '14rem' }}
                        onClick={() => {
                           handleChangeRole(Role.BackOffice);
                           handleClose();
                           router.push('/resumo');
                        }}
                     >
                        {ROLES_TYPES[Role.BackOffice]}
                     </MenuItem>
                  )}
               </Menu>
            )}
         </Stack>
      </MenuContainer>
   );
}
