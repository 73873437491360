'use client';

import { ApolloLink } from '@apollo/client';
import {
   ApolloNextAppProvider,
   NextSSRInMemoryCache,
   NextSSRApolloClient,
   SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr';
import { setVerbosity } from 'ts-invariant';
import authLink from './authLink';
import { errorLink } from './errorLink';
import { createGraphQLHttpLink } from './httpLink';

const httpLink = createGraphQLHttpLink();

setVerbosity('debug');

function makeClient() {
   return new NextSSRApolloClient({
      cache: new NextSSRInMemoryCache(),
      link: authLink.concat(
         ApolloLink.from(
            typeof window === 'undefined'
               ? [
                    new SSRMultipartLink({
                       stripDefer: true,
                    }),
                    errorLink,
                    httpLink,
                 ]
               : [errorLink, httpLink]
         )
      ),
   });
}

export function ApolloWrapper({ children }: React.PropsWithChildren) {
   return <ApolloNextAppProvider makeClient={makeClient}>{children}</ApolloNextAppProvider>;
}
