import { Role } from 'src/graphql/generated/graphql';

export const ROLES_TYPES = {
   [Role.Tenant]: 'Inquilino',
   [Role.Broker]: 'Corretor',
   [Role.Owner]: 'Proprietario',
   [Role.Admin]: 'Administrador',
   [Role.BackOffice]: 'Back Office',
};

export const ROLE = {
   Admin: Role.Admin,
   BackOffice: Role.BackOffice,
   Broker: Role.Broker,
   Owner: Role.Owner,
   Tenant: Role.Tenant,
};

export const STATUS = {
   approved: {
      text: 'Aprovado',
      color: 'success',
   },
   answered: {
      text: 'Respondido',
      color: 'success',
   },
   rejected: {
      text: 'Rejeitado',
      color: 'error',
   },
   PAID: {
      text: 'Pago',
      color: 'success',
   },
   LATE: {
      text: 'Atrazado',
      color: 'error',
   },
   CONFIRMED_PAID: {
      text: 'Confirmado',
      color: 'success',
   },
   CLOSED: {
      text: 'Baixado',
      color: 'error',
   },
   CANCELED: {
      text: 'Cancelado',
      color: 'error',
   },
   WAITING: {
      text: 'Aguardando',
      color: 'warning',
   },
   waiting: {
      text: 'Aguardando',
      color: 'warning',
   },
   invoiceDue: {
      text: 'A vencer',
      color: 'info',
   },
   late: {
      text: 'Atrasado',
      color: 'error',
   },
   paid: {
      text: 'Pago',
      color: 'success',
   },
   undefined: {
      text: 'Sem status',
      color: 'info',
   },
};
