import { Button, Grid, styled } from '@mui/material';

export const LayoutContainer = styled(Grid)(({ theme }) => ({
   backgroundColor: theme.palette.background.default,

   overflow: 'auto',
   gap: '16px',
   height: '100vh',
}));

export const AddButton = styled(Button)(({ theme }) => ({
   backgroundColor: theme.palette.primary.container,
   borderRadius: '6px',
   gap: '10px',
   padding: '10px 16px',
   height: '44px',

   textTransform: 'none',
   fontSize: '16px',
   fontWeight: '700',
}));
