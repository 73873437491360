export const Logo = ({
   width = 200,
   height = 40,
   color = "#025CF4"
}: {
   width?: number | string,
   height?: number | string,
   color?: string
}) => {
   return (
      <svg width={width} height={height} viewBox="0 0 150 26" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M14.0493 7.63591C14.0223 7.65501 13.9952 7.6757 13.9665 7.6964C13.2853 8.20737 12.6326 8.75495 12.0118 9.33437C11.7412 9.58588 11.477 9.84534 11.2207 10.1096C10.8052 10.5362 10.4089 10.9771 10.0268 11.4324C8.77088 12.9335 7.70277 14.5969 6.85434 16.3845C6.80499 16.4848 6.75883 16.5867 6.71267 16.6869C6.42773 17.3093 6.16986 17.9461 5.94064 18.5955C5.49175 19.8642 5.14951 21.1822 4.92347 22.5416C4.92188 22.5432 4.92188 22.5464 4.92188 22.548C4.898 22.6928 4.87572 22.8393 4.85343 22.9857C4.84866 23.0415 4.84706 23.0988 4.84706 23.1561C4.84706 24.5505 5.97725 25.6791 7.37168 25.6791C7.32711 25.6791 7.28413 25.6791 7.24115 25.6775C6.32267 25.6616 5.44558 25.4769 4.64013 25.1554C1.92131 24.0714 0 21.4146 0 18.309C0 17.8633 0.0397946 17.4271 0.116202 17.0037C0.125752 16.9544 0.135303 16.905 0.144854 16.8557C0.15918 16.7856 0.1751 16.714 0.189426 16.6456C0.189426 16.644 0.189425 16.644 0.191017 16.644C0.732233 14.1146 1.68573 11.7364 2.9735 9.58429C2.97509 9.58269 2.97509 9.58269 2.97509 9.58269C3.27117 9.08605 3.58635 8.60214 3.91904 8.13255C5.17338 6.64899 7.04854 5.70663 9.14336 5.70663C9.24524 5.70663 9.34711 5.70823 9.44581 5.713C10.6301 5.76553 11.7364 6.11732 12.6899 6.69833C12.6995 6.70311 12.709 6.70948 12.717 6.71425C12.822 6.78111 12.9255 6.84796 13.029 6.918C13.376 7.14882 13.7166 7.38759 14.0493 7.63591Z" fill={color} />
         <path d="M13.0289 6.9196C12.9254 6.84956 12.822 6.78271 12.7169 6.71585C12.8236 6.77952 12.927 6.84797 13.0289 6.9196Z" fill={color} />
         <path d="M13.0289 6.9196C12.9254 6.84956 12.822 6.78271 12.7169 6.71585C12.8236 6.77952 12.927 6.84797 13.0289 6.9196Z" fill={color} />
         <path d="M13.0289 6.9196C12.9254 6.84956 12.822 6.78271 12.7169 6.71585C12.8236 6.77952 12.927 6.84797 13.0289 6.9196Z" fill={color} />
         <path d="M23.2882 23.3694V23.3726C23.1815 24.654 22.1166 25.6632 20.8113 25.6807C20.8113 25.6823 20.8097 25.6823 20.8081 25.6807C20.7684 25.6807 20.7286 25.6807 20.6904 25.6791C20.6681 25.6791 20.6458 25.6775 20.6251 25.6775C20.5391 25.6743 20.4548 25.6711 20.3704 25.6648C20.3688 25.6648 20.3688 25.6648 20.3688 25.6648C17.9827 25.4594 16.1123 23.4585 16.1123 21.0199C16.1123 18.4459 18.1992 16.3575 20.7747 16.3575C20.9307 16.3575 21.0851 16.3654 21.2348 16.3813C22.0991 18.2008 22.7342 20.1491 23.1051 22.1914C23.1768 22.5814 23.2373 22.973 23.2882 23.3694Z" fill={color} />
         <path d="M28 18.3122C28 21.4783 26.0039 24.178 23.2023 25.2207C22.4016 25.5199 21.5357 25.6823 20.6299 25.6823H20.5726C20.5679 25.6823 20.5631 25.6823 20.5599 25.6807C21.8652 25.6632 22.9301 24.6556 23.0368 23.3726V23.3694C22.9858 22.973 22.9253 22.5798 22.8553 22.1898C22.4844 20.1491 21.8493 18.1992 20.9849 16.3797C20.146 14.6128 19.0922 12.9685 17.8554 11.4801C17.233 10.7288 16.5629 10.0173 15.8513 9.35189C15.2003 8.74063 14.5158 8.16758 13.7995 7.63592C13.4668 7.38759 13.1261 7.14723 12.7791 6.9196C12.6772 6.84797 12.5738 6.77952 12.4671 6.71585C12.4592 6.71108 12.4512 6.70312 12.4401 6.69993C11.4866 6.11892 10.3803 5.76713 9.19596 5.7146C9.09567 5.70982 8.99539 5.70823 8.89351 5.70823C6.7971 5.70823 4.92354 6.64899 3.66919 8.13415C4.68317 6.69356 5.85793 5.37236 7.1664 4.19601C8.48442 3.01011 9.94092 1.97225 11.5041 1.10949C11.5375 1.09039 11.5693 1.07288 11.6028 1.05537C12.3159 0.666968 13.0497 0.313587 13.8058 0C14.5285 0.294485 15.2337 0.625581 15.9166 0.986922C15.9882 1.02513 16.0598 1.06333 16.1299 1.10153C16.1378 1.10631 16.1458 1.11108 16.1538 1.11427C17.7233 1.96907 19.1846 2.99738 20.509 4.17532C24.1335 7.39874 26.7457 11.7348 27.8138 16.6472C27.8265 16.6997 27.8377 16.7522 27.8488 16.8047C27.8504 16.8175 27.8536 16.8318 27.8568 16.8461C27.8583 16.8541 27.8599 16.8621 27.8615 16.87C27.8711 16.9162 27.8806 16.9607 27.8886 17.0069C27.9602 17.4303 28 17.8665 28 18.3122Z" fill={color} />
         <path d="M12.0341 21.0198C12.0341 23.5508 10.0172 25.6106 7.50377 25.6807C7.46079 25.6822 7.41781 25.6822 7.37324 25.6822C5.97882 25.6822 4.84863 24.5537 4.84863 23.1592C4.84863 23.1019 4.85022 23.0446 4.855 22.9889C4.87569 22.8425 4.89798 22.6976 4.92345 22.5512C4.92345 22.5496 4.92345 22.5464 4.92504 22.5448C5.15108 21.1854 5.49172 19.8674 5.94221 18.5987C6.17143 17.9476 6.43089 17.3125 6.71423 16.6901C6.7604 16.5898 6.80815 16.4879 6.8559 16.3877C7.02623 16.3686 7.19814 16.359 7.37324 16.359C8.65943 16.359 9.82304 16.8795 10.6683 17.7216C11.0265 18.0798 11.3273 18.4968 11.5549 18.9568C11.8621 19.5777 12.0341 20.278 12.0341 21.0198Z" fill={color} />
         <path d="M35.687 20.4834L40.0297 7.08447H44.9927L49.4981 20.4834H45.3789L43.8236 15.2861C43.4873 14.1581 43.1728 12.989 42.88 11.7742C42.5871 10.5595 42.3073 9.35342 42.0427 8.15387H42.8691C42.6175 9.35342 42.3615 10.5595 42.1056 11.7742C41.8474 12.989 41.5568 14.1581 41.2336 15.2861L39.7499 20.4834H35.687ZM38.9061 17.913V15.1972H46.2812V17.913H38.9061Z" fill={color} />
         <path d="M54.446 7.08447V20.4834H50.83V7.08447H54.446Z" fill={color} />
         <path d="M59.7865 20.6092C59.0793 20.6092 58.4611 20.4465 57.934 20.1233C57.4069 19.8001 56.9991 19.3533 56.7106 18.7828C56.4221 18.2123 56.2789 17.5637 56.2789 16.8305V10.4272H59.8949V16.0757C59.8949 16.5724 60.0207 16.965 60.2724 17.2492C60.524 17.5333 60.8776 17.6765 61.3331 17.6765C61.6324 17.6765 61.8906 17.6114 62.1075 17.4835C62.3222 17.3555 62.4914 17.1711 62.6107 16.9303C62.73 16.6917 62.7908 16.4054 62.7908 16.0757V10.4272H66.4068V20.4813H63.0164L62.9448 17.9086H63.0532C62.8255 18.67 62.4545 19.3099 61.9426 19.8283C61.4285 20.3511 60.7105 20.6092 59.7865 20.6092Z" fill={color} />
         <path d="M73.0422 24.4595C72.1247 24.4595 71.3221 24.3446 70.6323 24.1124C69.9425 23.8825 69.3893 23.555 68.9729 23.132C68.5564 22.709 68.3004 22.2101 68.205 21.6353L71.3785 21.1667C71.4154 21.3468 71.5043 21.5008 71.6475 21.6309C71.7906 21.7589 71.9793 21.8587 72.2136 21.9281C72.4479 21.9975 72.7169 22.0322 73.0227 22.0322C73.6106 22.0322 74.0487 21.9042 74.3351 21.6461C74.6236 21.388 74.7667 20.9954 74.7667 20.4682V18.7589H74.5064C74.3806 19.0952 74.1941 19.3837 73.9489 19.6223C73.7038 19.8609 73.4045 20.0453 73.0487 20.1711C72.6952 20.2969 72.2895 20.3598 71.834 20.3598C71.1203 20.3598 70.4631 20.1928 69.8644 19.8609C69.2657 19.529 68.7841 19.0019 68.4219 18.2817C68.0596 17.5616 67.8774 16.6245 67.8774 15.4661C67.8774 14.2492 68.0683 13.2623 68.4479 12.5031C68.8275 11.7439 69.3178 11.1885 69.9186 10.835C70.5173 10.4814 71.1507 10.3035 71.8167 10.3035C72.3134 10.3035 72.7516 10.3881 73.129 10.5595C73.5064 10.7309 73.8253 10.9586 74.0878 11.2471C74.3481 11.5356 74.5455 11.8501 74.6778 12.1907H74.7494V10.4272H78.3285V20.1038C78.3285 21.0452 78.1072 21.8392 77.6669 22.4877C77.2265 23.1342 76.6105 23.6244 75.8188 23.9584C75.0292 24.2925 74.103 24.4595 73.0422 24.4595ZM73.168 17.7872C73.5043 17.7872 73.7906 17.6961 74.027 17.5138C74.2635 17.3316 74.4457 17.0648 74.5758 16.7178C74.7038 16.3707 74.7689 15.9542 74.7689 15.4683C74.7689 14.9716 74.7038 14.5443 74.5758 14.1863C74.4479 13.8306 74.2635 13.5551 74.027 13.3642C73.7906 13.1733 73.5043 13.0757 73.168 13.0757C72.8383 13.0757 72.5563 13.1712 72.3221 13.3642C72.0878 13.5551 71.9099 13.8306 71.7863 14.1863C71.6626 14.5421 71.6019 14.9694 71.6019 15.4683C71.6019 15.9607 71.6626 16.3794 71.7863 16.7264C71.9099 17.0735 72.0878 17.3381 72.3221 17.5182C72.5563 17.6961 72.8383 17.7872 73.168 17.7872Z" fill={color} />
         <path d="M83.6711 20.6092C82.964 20.6092 82.3458 20.4465 81.8187 20.1233C81.2915 19.8001 80.8837 19.3533 80.5952 18.7828C80.3067 18.2123 80.1636 17.5637 80.1636 16.8305V10.4272H83.7796V16.0757C83.7796 16.5724 83.9054 16.965 84.157 17.2492C84.4086 17.5333 84.7622 17.6765 85.2177 17.6765C85.5171 17.6765 85.7752 17.6114 85.9921 17.4835C86.209 17.3555 86.3761 17.1711 86.4954 16.9303C86.6147 16.6917 86.6754 16.4054 86.6754 16.0757V10.4272H90.2914V20.4813H86.901L86.8294 17.9086H86.9379C86.7101 18.67 86.3392 19.3099 85.8273 19.8283C85.3132 20.3511 84.5952 20.6092 83.6711 20.6092Z" fill={color} />
         <path d="M96.9443 20.6635C95.8705 20.6635 94.9465 20.4574 94.1699 20.0474C93.3933 19.6375 92.799 19.0453 92.3847 18.2709C91.9704 17.4965 91.7643 16.5681 91.7643 15.4835C91.7643 14.4466 91.9725 13.5399 92.389 12.7634C92.8055 11.9868 93.3955 11.3838 94.1569 10.9521C94.9183 10.5204 95.8185 10.3035 96.8553 10.3035C97.6167 10.3035 98.3043 10.4228 98.9182 10.6593C99.5321 10.8957 100.059 11.2384 100.497 11.6853C100.936 12.1321 101.272 12.6723 101.504 13.3078C101.738 13.9434 101.855 14.6636 101.855 15.4661V16.2926H92.8814V14.3143H100.165L98.4757 14.7286C98.4757 14.3208 98.415 13.9781 98.2957 13.6983C98.1764 13.4185 97.9985 13.2059 97.7642 13.0605C97.5299 12.913 97.2393 12.8393 96.8922 12.8393C96.5451 12.8393 96.2545 12.913 96.0202 13.0605C95.7859 13.208 95.6102 13.4206 95.4888 13.6983C95.3673 13.9759 95.3087 14.3208 95.3087 14.7286V16.1668C95.3087 16.6115 95.3803 16.9781 95.5191 17.2687C95.6601 17.5594 95.8597 17.7763 96.1178 17.9173C96.3759 18.0583 96.6753 18.1277 97.018 18.1277C97.2631 18.1277 97.4866 18.093 97.6883 18.0236C97.89 17.9542 98.0614 17.8566 98.2045 17.7264C98.3477 17.5984 98.4562 17.4401 98.5278 17.2535L101.712 17.7221C101.545 18.3208 101.248 18.8414 100.823 19.2817C100.398 19.7221 99.8596 20.0626 99.2089 20.3034C98.5581 20.5442 97.8011 20.6635 96.9443 20.6635Z" fill={color} />
         <path d="M104.267 6.9066V5.55737H115.807V6.9066H110.803V21.3901H109.293V6.9066H104.267Z" fill={color} />
         <path d="M121.705 21.6439C120.671 21.6439 119.762 21.3836 118.974 20.863C118.187 20.3424 117.571 19.6201 117.126 18.696C116.679 17.772 116.456 16.7112 116.456 15.5139C116.456 14.2948 116.679 13.2232 117.126 12.2992C117.573 11.3751 118.189 10.6528 118.974 10.1322C119.762 9.61156 120.671 9.35126 121.705 9.35126C122.731 9.35126 123.64 9.61156 124.43 10.1322C125.219 10.6528 125.838 11.3751 126.285 12.2992C126.731 13.2232 126.955 14.2948 126.955 15.5139C126.955 16.7112 126.734 17.772 126.291 18.696C125.849 19.6201 125.233 20.3424 124.443 20.863C123.653 21.3836 122.738 21.6439 121.705 21.6439ZM121.705 20.3381C122.519 20.3381 123.211 20.119 123.777 19.6787C124.343 19.2405 124.775 18.6548 125.068 17.926C125.36 17.1971 125.508 16.3924 125.508 15.5139C125.508 14.6288 125.358 13.8197 125.061 13.0866C124.764 12.3534 124.332 11.7655 123.764 11.323C123.198 10.8805 122.51 10.6593 121.703 10.6593C120.896 10.6593 120.211 10.8805 119.647 11.323C119.083 11.7655 118.653 12.3534 118.356 13.0866C118.059 13.8197 117.909 14.6288 117.909 15.5139C117.909 16.3924 118.057 17.1971 118.35 17.926C118.643 18.6548 119.072 19.2405 119.636 19.6787C120.2 20.1168 120.89 20.3381 121.705 20.3381Z" fill={color} />
         <path d="M133.987 9.50959V10.7634H128.334V9.50959H133.987ZM130.098 6.68317H131.543V18.5832C131.543 19.1711 131.673 19.6027 131.935 19.8804C132.198 20.1559 132.597 20.2665 133.137 20.2101C133.237 20.2101 133.358 20.1971 133.503 20.1732C133.649 20.1494 133.785 20.1212 133.913 20.093L134.211 21.3359C134.054 21.3858 133.883 21.4248 133.694 21.453C133.506 21.4812 133.317 21.4986 133.126 21.5073C132.176 21.5702 131.434 21.3467 130.9 20.8326C130.367 20.3186 130.098 19.6027 130.098 18.6808V6.68317Z" fill={color} />
         <path d="M139.725 21.6548C139.002 21.6548 138.345 21.516 137.753 21.2405C137.161 20.965 136.69 20.5572 136.339 20.0192C135.987 19.4813 135.814 18.8284 135.814 18.0648C135.814 17.477 135.924 16.9824 136.143 16.5833C136.363 16.1841 136.679 15.8544 137.089 15.5941C137.499 15.336 137.983 15.1321 138.54 14.9824C139.096 14.8327 139.712 14.7178 140.384 14.631C141.044 14.5464 141.603 14.4727 142.063 14.4141C142.523 14.3534 142.877 14.2557 143.119 14.1213C143.365 13.9868 143.486 13.7699 143.486 13.4727V13.1972C143.486 12.6657 143.378 12.2081 143.163 11.8263C142.948 11.4445 142.631 11.1495 142.217 10.9434C141.803 10.7374 141.297 10.6354 140.703 10.6354C140.143 10.6354 139.651 10.7222 139.226 10.8957C138.801 11.0692 138.449 11.297 138.169 11.5747C137.89 11.8545 137.679 12.1603 137.536 12.4944L136.154 12.0258C136.417 11.3816 136.786 10.8632 137.258 10.4749C137.731 10.0866 138.267 9.80028 138.864 9.62024C139.46 9.4402 140.061 9.34909 140.67 9.34909C141.139 9.34909 141.621 9.412 142.115 9.53564C142.61 9.65928 143.072 9.86969 143.497 10.1625C143.922 10.4575 144.265 10.861 144.527 11.3729C144.79 11.887 144.92 12.5356 144.92 13.323V21.388H143.506V19.5073H143.399C143.23 19.8696 142.978 20.2145 142.644 20.5442C142.31 20.8739 141.903 21.1407 141.417 21.3468C140.935 21.5528 140.369 21.6548 139.725 21.6548ZM139.937 20.3685C140.66 20.3685 141.287 20.2079 141.818 19.8891C142.349 19.5702 142.759 19.1429 143.05 18.6028C143.341 18.0648 143.486 17.4726 143.486 16.8284V15.1386C143.38 15.2384 143.206 15.3273 142.965 15.4032C142.725 15.4792 142.449 15.5486 142.141 15.605C141.833 15.6614 141.523 15.7113 141.211 15.7546C140.898 15.798 140.627 15.8327 140.393 15.8609C139.733 15.939 139.169 16.067 138.699 16.2427C138.228 16.4206 137.87 16.6614 137.625 16.965C137.38 17.2709 137.258 17.6548 137.258 18.1234C137.258 18.5984 137.376 19.0019 137.61 19.3338C137.844 19.6678 138.163 19.9216 138.566 20.0995C138.97 20.2774 139.428 20.3685 139.937 20.3685Z" fill={color} />
         <path d="M150 5.55737V21.3901H148.555V5.55737H150Z" fill={color} />
      </svg>

   )
};
