import {
   AccountBalanceWalletOutlined as AccountBalanceWalletOutlinedIcon,
   AssignmentOutlined as AssignmentOutlinedIcon,
   HomeWorkOutlined as HomeWorkOutlinedIcon,
   ChatOutlined as ChatOutlinedIcon,
   PermIdentityOutlined as PermIdentityOutlinedIcon,
   PersonPinOutlined as PersonPinOutlinedIcon,
   PersonPinCircleOutlined as PersonPinCircleOutlinedIcon,
   PaymentsOutlined as PaymentsOutlinedIcon,
   RecentActorsOutlined,
   DocumentScannerOutlined,
} from '@mui/icons-material';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';

export const navigationItems = [
   { path: '/resumo', icon: <AccountBalanceWalletOutlinedIcon />, label: 'Resumo' },
   { path: '/faturas', icon: <PaymentsOutlinedIcon />, label: 'Faturas' },
   { path: '/iptus', icon: <DocumentScannerOutlined />, label: 'IPTUs' },
   // { path: '/contratos', icon: <AssignmentOutlinedIcon />, label: 'Contratos' },
   { path: '/imoveis', icon: <HomeWorkOutlinedIcon />, label: 'Imóveis' },
   { path: '/chamadas', icon: <ChatOutlinedIcon />, label: 'Chamadas' },
   { path: '/inquilinos', icon: <PersonPinOutlinedIcon />, label: 'Inquilinos' },
   { path: '/corretores', icon: <RecentActorsOutlined />, label: 'Corretores' },
   { path: '/propietarios', icon: <PersonPinCircleOutlinedIcon />, label: 'Proprietarios' },
   { path: '/perfil', icon: <PermIdentityOutlinedIcon />, label: 'Meu Perfil' },
];
