'use client';

import 'src/styles/globals.css';
import theme from 'src/styles/theme';
import { ThemeProvider } from '@mui/material/styles';
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from 'src/createEmotionCache';
import Head from 'next/head';
import { Layout } from 'src/layout';
import { CreateUserProvider } from 'src/contexts/user';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ThemeRegistry from 'src/styles/theme-registry';
import type { Metadata } from 'next'
import { ApolloWrapper } from 'src/apollo/apollo-wrapper';

const clientSideEmotionCache = createEmotionCache();


export default function RootLayout({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <html lang="en">
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <body>
      <ApolloWrapper>
        <ThemeRegistry>
          <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <SnackbarProvider maxSnack={1} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                  <CreateUserProvider>
                    <Layout>
                      {children}
                    </Layout>
                  </CreateUserProvider>
                </SnackbarProvider>
              </LocalizationProvider>
          </ThemeProvider>
        </ThemeRegistry>
        </ApolloWrapper>
      </body>
    </html>
  )
}
