import { HttpLink } from '@apollo/client/link/http';

export const GRAPHQL_URL = new URL(process.env.NEXT_PUBLIC_BACKEND_SERVER + '/graphql').toString();

export function createGraphQLHttpLink() {
   return new HttpLink({
      credentials: 'include',
      uri: GRAPHQL_URL,
      fetchOptions: { cache: 'no-store' },
   });
}
