import { setCookie, destroyCookie } from 'nookies';
import { createContext, useState } from 'react';
import {
   GetUserQuery,
   GetUserRolesQuery,
   Role,
   useAuthenticateMutation,
   useGetUserQuery,
   useGetUserRolesQuery,
   useSignOutMutation,
} from 'src/graphql/generated/graphql';
import useNotification from 'src/hooks/useNotification';

export interface UserContextProps {
   user: GetUserQuery | undefined | null;
   userRoles: GetUserRolesQuery | undefined;
   handleChangeRole: (newRole: Role) => void;
   handleSignOut: any;
   submitForm: any;
}

export const UserContext = createContext<UserContextProps>({} as UserContextProps);

export interface CreateUserProviderProps {
   children: React.ReactNode;
}

const setAccessToken = (token: any) => {
   setCookie(null, 'session', token, { maxAge: 24 * 60 * 60 }); // Set the token in a cookie, expires in 1 day
};

const setOtherRole = (role: Role) => {
   setCookie(null, 'otherRole', role, { maxAge: 24 * 60 * 60 }); // Set the token in a cookie, expires in 1 day
};

export const CreateUserProvider = ({ children }: CreateUserProviderProps) => {
   const [_msg, sendNotification] = useNotification();
   const [user, setUser] = useState<GetUserQuery | null>(null);
   const { data: userData } = useGetUserQuery({
      onCompleted: (data) => {
         setUser(data);
      },
   });
   const { data: userRoles } = useGetUserRolesQuery();
   const [signOutMutation] = useSignOutMutation({
      onCompleted: () => {
         destroyCookie(null, 'session');
         destroyCookie(null, 'otherRole');
         window.location.replace('/');
      },
      onError: (error) => {
         sendNotification({
            msg: error.message,
            variant: 'error',
         });
      },
   });

   const handleSignOut = async () => {
      await signOutMutation();
   };

   const handleChangeRole = async (newRole: Role) => {
      setUser({
         getUser: {
            ...user?.getUser,
            role: newRole,
         },
      } as GetUserQuery);
      setOtherRole(newRole);
      window.location.reload();
   };

   const [submitForm, { loading, error, data }] = useAuthenticateMutation({
      onCompleted: async (data) => {
         setAccessToken(data.authenticate.token);
         window.location.replace('/imoveis');
      },
      onError: (error) => {
         sendNotification({
            msg: 'Usuario ou senha incorretos',
            variant: 'error',
         });
      },
   });

   const values = {
      user,
      userRoles,
      handleChangeRole,
      handleSignOut,
      submitForm,
   };

   return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};
