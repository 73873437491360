'use client';
import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import NextAppDirEmotionCacheProvider from './createEmotionCache';
import theme from './theme';
import { GlobalStyles } from '@mui/material';

export default function ThemeRegistry({ children }: { children: React.ReactNode }) {
   return (
      <NextAppDirEmotionCacheProvider options={{ key: 'mui' }}>
         <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            {children}
         </ThemeProvider>
      </NextAppDirEmotionCacheProvider>
   );
}
