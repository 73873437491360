import type { NextRequest } from 'next/server';
import { NextResponse } from 'next/server';
import { AuthMiddlewarePageStatus, AuthVerifyUserSessionEntity } from './graphql/generated/graphql';
import { GRAPHQL_URL } from './apollo/httpLink';

type UserRole = 'ADMIN' | 'BACK_OFFICE' | 'BROKER' | 'OWNER' | 'TENANT';

export const ROLE_PERMISSIONS: { [x: string]: UserRole[] } = {
   '/chamadas': ['ADMIN', 'BACK_OFFICE', 'BROKER', 'OWNER', 'TENANT'],
   '/resumo': ['ADMIN', 'BACK_OFFICE', 'BROKER', 'OWNER'], //
   '/iptus': ['ADMIN', 'BACK_OFFICE', 'BROKER', 'OWNER', 'TENANT'],
   '/imoveis': ['ADMIN', 'BACK_OFFICE', 'BROKER', 'OWNER', 'TENANT'],
   '/inquilinos': ['ADMIN', 'BACK_OFFICE', 'BROKER', 'OWNER'],
   '/corretores': ['ADMIN', 'BACK_OFFICE'],
   '/faturas': ['ADMIN', 'BACK_OFFICE', 'TENANT'],
   '/perfil': ['ADMIN', 'BACK_OFFICE', 'BROKER', 'OWNER', 'TENANT'],
   '/propietarios': ['ADMIN', 'BACK_OFFICE', 'BROKER'],
   '/solicitacao': ['ADMIN', 'BACK_OFFICE'],
};

const PUBLIC_PAGES = [`/`, `register(\\/?)`];

const PRIVATE_PAGES: string[] = [
   `chamadas(\\/)?`,
   `aluguel-req(\\/)?`,
   `resumo(\\/)?`,

   `iptus(\\/)?`,
   `imoveis(\\/)?`,
   `inquilinos(\\/)?`,

   `pagamentos(\\/)?`,
   `perfil(\\/)?`,
   `propietarios(\\/)?`,
];

const transformEndpointToRegex = (reg: string) => new RegExp(`(?:^(\\/)(?:(\\/)?${reg}))`, 'iu');

const PUBLIC_FILE = /\.(.*)$/;
const REDIRECT_PRIVATE_PAGE = '/';
const REDIRECT_PUBLIC_PAGE = '/?login=true';

const QUERY_VERIFY_USE_SESSION = `
      query verifyUserSession($session: String!, $pathname: String!, $otherRole: String!) {
        verifyUserSession(session: $session, pathname: $pathname, otherRole: $otherRole) {
          redirectPathname
          status
        }
      }
    `;

const middlewareUrlPathname = (origin: string, pathname: string) => new URL(`${origin}${pathname}`).toString();

export async function middleware(req: NextRequest) {
   // Perform some operations in the middleware

   if (PUBLIC_FILE.test(req.nextUrl.pathname)) {
      return;
   }

   const nextPage = NextResponse.next();

   const url = req.nextUrl.clone();
   const _redirectPublicPage = new URL(`${url.origin}${REDIRECT_PUBLIC_PAGE}`).toString();
   const _redirectPrivatePage = new URL(`${url.origin}${REDIRECT_PRIVATE_PAGE}`).toString();

   const getSessionToken = req.cookies.get('session');
   const hasToken = getSessionToken && getSessionToken.value.length > 12;

   const getOtherRole = req.cookies.get('otherRole');
   const hasOtherRole = getOtherRole && getOtherRole.value !== '';

   const isIntoPrivatePages = PRIVATE_PAGES.find((pages) => {
      const reg = transformEndpointToRegex(pages);
      return reg.test(url.pathname);
   });

   if (!!hasToken === false) {
      if (!!isIntoPrivatePages) {
         return NextResponse.redirect(_redirectPublicPage);
      }
   }

   const verifyUserSession = await fetch(GRAPHQL_URL, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify({
         query: QUERY_VERIFY_USE_SESSION,
         variables: {
            session: hasToken ? getSessionToken?.value : '',
            otherRole: hasOtherRole ? getOtherRole?.value : '',
            pathname: url.pathname,
         },
      }),
   });

   const result = await verifyUserSession.json();
   const data = result?.data || {};
   const isUserLogged: AuthVerifyUserSessionEntity = data && data?.verifyUserSession ? data.verifyUserSession : {};

   if (isUserLogged.status === AuthMiddlewarePageStatus.Signout) {
      nextPage.cookies.delete('session');
   }
   if (isUserLogged.redirectPathname) {
      return NextResponse.redirect(middlewareUrlPathname(url.origin, isUserLogged.redirectPathname));
   }

   const isPublicPage = PUBLIC_PAGES.some((pages) => {
      const reg = transformEndpointToRegex(pages);
      return reg.test(url.pathname);
   });

   if (isUserLogged.status !== AuthMiddlewarePageStatus.Signout && isPublicPage) {
      return NextResponse.redirect(_redirectPrivatePage);
   }

   // Continue to the next middleware or the route handler
   return nextPage;
}

export const config = {
   matcher: [
      /*
       * Match all request paths except for the ones starting with:
       * - api (API routes)
       * - _next/static (static files)
       * - favicon.ico (favicon file)
       */
      '/((?!api|_next/static|favicon.ico).*)',
   ],
};
