import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import { ptBR as corPtBR } from '@mui/material/locale';
import { ptBR } from '@mui/x-date-pickers/locales';

let theme = createTheme(
   {
      components: {
         MuiButton: {
            styleOverrides: {
               root: {
                  fontWeight: 600,
                  textTransform: "none",
                  boxShadow: "none",
                  borderRadius: "0.5rem",

                  ":hover": {
                     boxShadow: "none",
                  }
               }
            }
         },
         MuiSwitch: {
            styleOverrides: {
               colorPrimary: '#4664EB',
               colorSecondary: '#4664EB',
               switchBase: {
                  color: '#DEDBF4',
                  '&.Mui-checked': {
                     color: '#fff',
                  },
               },
            },
         },
         MuiOutlinedInput: {
            styleOverrides: {
               root: {
                  background: "#FFF",
                  borderRadius: "0.5rem",
               },
            },
         },
         MuiInput: {
            styleOverrides: {
               root: {
                  background: '#fff',
                  color: '#383838',
                  '&.Mui-focused': {
                     color: '#4664EB',
                     backgroundColor: '#fff',
                  },
                  '&:has(input:-webkit-autofill)': {
                     backgroundColor: 'white !important',
                  },
                  '& input:-webkit-autofill': {
                     '-webkit-box-shadow': '0 0 0 1000px white inset !important',
                     '-webkit-text-fill-color': '#383838 !important',
                  },
               },
            },
         },
      },
      palette: {
         primary: {
            container: '#4664EB',
            dark: '#2B48C9',
            light: '#FFFFFF',
            main: '#4664EB',
         },
         secondary: {
            dark: "#000",
            main: "#010101",  
            light: "#101010",
         },
         common: {
            black: '#000',
            white: '#fff',
         },
         background: {
            default: '#F4F5F6', // #FFFFFF
            paper: '#F4F5F6',
         },
         text: {
            high: '#6F7B92',
            medium: '#383838',
            low: '#5E6A73',
            white: '#F3F3F3',
         },
      },
      typography: {
         fontFamily: '"Raleway", sans-serif',
         h1: {
            fontSize: '2rem',
            fontWeight: 500,
         },
         h2: {
            fontSize: '1.5rem',
            fontWeight: 700,
         },
         h3: {
            fontSize: '1.25rem',
            fontWeight: 700,
         },
         h4: {
            fontSize: '1rem',
            fontWeight: 400,
         },
         h5: {
            fontSize: '1rem',
            fontWeight: 500,
            fontStyle: 'normal',
            lineHeight: 'normal',
         },
         h6: {
            fontSize: '16px',
            fontWeight: 400,
         },
         subtitle1: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '150%',
         },
         subtitle2: {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '150%',
         },

         body1: {
            fontWeight: 500,
            fontStyle: 'normal',
            lineHeight: 'normal',
         },
         body2: {
            fontSize: '0.9rem',
         },
         button: {
            fontSize: '14px',
            fontWeight: 400,
         },
      },
      breakpoints: {
         values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
         },
      },
   },
   corPtBR,
   ptBR
);

const responsiveTheme = responsiveFontSizes(theme);
export default responsiveTheme;
