import React, { useContext } from 'react';
import {
   IconButton,
   Stack,
   SwipeableDrawer,
   Typography,
   useMediaQuery,
   List,
   useTheme,
   ListItemButton,
} from '@mui/material';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Role } from 'src/graphql/generated/graphql';
import NavBar from './NavBar';
import { MobileMediaQuery } from 'src/utils/constants';
import { Logo } from 'src/components/svgs/Logo';
import { ExitToAppOutlined, MenuOutlined, NotificationsNoneOutlined } from '@mui/icons-material';

import { AddButton } from './styles';
import useNotification from 'src/hooks/useNotification';
import { navigationItems } from 'src/@data/navigation';
import { ROLE_PERMISSIONS } from 'src/middleware';
import { UserContext } from 'src/contexts/user';
import { usePathname, useRouter } from 'next/navigation';

export const renderMenuItem = ({ item, currentPage }) => {
   const { palette } = useTheme();
   const router = useRouter();

   return (
      <ListItemButton
         key={item.path}
         sx={{
            mb: '16px',
            backgroundColor: currentPage.startsWith(item.path) ? palette.primary.main : 'transparent',
            borderRadius: '8px',
            color: currentPage.startsWith(item.path) ? '#FFFFFF' : 'black',
            border: '1px solid transparent',
            paddingY: '0.75rem',

            '&:hover': {
               backgroundColor: currentPage.startsWith(item.path) ? palette.primary.main : '#E9F4FF',
               color: currentPage.startsWith(item.path) ? '#FFFFFF' : 'black',
               borderColor: 'blue',
            },
            '& .MuiSvgIcon-root': {
               fontSize: 24,
            },
         }}
         onClick={item.onClick ? item.onClick : () => router.push(item.path)}
      >
         {item.icon}
         <Typography
            variant="h4"
            sx={{
               paddingLeft: '1rem',
               fontWeight: currentPage.startsWith(item.path) ? 600 : 300,
            }}
         >
            {item.label}
         </Typography>
      </ListItemButton>
   );
};

interface LayoutProps {
   children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
   const { push } = useRouter();
   const pathname = usePathname();
   const { user, handleSignOut } = useContext(UserContext);

   if (
      pathname === '/' ||
      pathname === '/404' ||
      pathname === '/register' ||
      pathname === '/forgot-password' ||
      pathname === '/reset-password'
   ) {
      return children;
   }

   const isMobile = useMediaQuery(MobileMediaQuery);
   const [_msg, sendNotification] = useNotification();

   const titles = {
      '/imoveis': 'Imóveis',
      '/inquilinos': 'Inquilinos',
      '/corretores': 'Corretores',
      '/faturas': 'Faturas',
      '/chamadas': 'Chamadas',
      '/propietarios': 'Proprietários',
      '/iptus': 'IPTUs',
      '/resumo': 'Resumo',
      '/perfil': 'Perfil',
      '/contratos': 'Contratos',
   };

   const addButtonConfig = {
      '/imoveis': {
         text: 'Adicionar Imóvel',
         link: '/imoveis/editar/novo',
         showFor: [Role.Owner, Role.Admin, Role.BackOffice],
      },
      '/chamadas': {
         text: 'Abir Chamado',
         link: '/chamadas/editar/novo',
         showFor: [Role.Tenant, Role.Owner, Role.Broker],
      },
   };

   const role = user?.getUser.role;
   const renderAddButton = addButtonConfig[pathname] && user?.getUser.role !== Role.Tenant;

   const [isMenuOpen, setIsMenuOpen] = React.useState(false);

   const notificationItems = [
      {
         label: 'Sair',
         onClick: handleSignOut, // Assuming handleSignOut is a function in your component
         icon: <ExitToAppOutlined />,
      },
   ];

   const handleMenuToggle = () => {
      setIsMenuOpen((prev) => !prev);
   };

   const currentPage = pathname;

   return (
      <>
         <Stack style={{ position: 'fixed' }}>
            {!isMobile && <NavBar role={role} notificationItems={notificationItems} currentPage={currentPage} />}
         </Stack>

         {isMobile && (
            <Stack
               sx={{
                  background: '#fff',
                  height: 80,
                  zIndex: 5,
                  alignItems: 'center',
                  boxShadow: '0px 0px 10px 10px #00000020',
               }}
               direction="row"
               position="fixed"
               justifyContent="space-between"
               width="100%"
            >
               <Stack>
                  <IconButton size="large" onClick={handleMenuToggle}>
                     <MenuOutlined />
                  </IconButton>
               </Stack>
               <Stack width="100%" alignItems="center">
                  <Logo />
               </Stack>
               <Stack width={60}>
                  <IconButton size="large">
                     <NotificationsNoneOutlined />
                  </IconButton>
               </Stack>
            </Stack>
         )}

         <Stack
            style={{
               marginLeft: isMobile ? 0 : '18rem',
               padding: isMobile ? '100px 1rem 0 1rem' : '2.5rem 2rem 0 2rem',
               // width: '100%',
               maxWidth: 1400,
               minHeight: '100vh',
            }}
            gap={1}
         >
            {titles[pathname] && (
               <Stack
                  direction={isMobile ? 'column' : 'row'}
                  gap={2}
                  height={46}
                  justifyContent="space-between"
                  alignItems="center"
               >
                  <Typography variant="h1" fontWeight={700}>
                     {titles[pathname]}
                  </Typography>
                  {addButtonConfig[pathname]?.showFor?.includes(user?.getUser.role) && (
                     <AddButton
                        variant="contained"
                        sx={{
                           width: '100%',
                           maxWidth: isMobile ? '100%' : 'max-content',
                        }}
                        startIcon={<AddOutlinedIcon />}
                        onClick={() => push(addButtonConfig[pathname].link)}
                     >
                        {addButtonConfig[pathname].text}
                     </AddButton>
                  )}
               </Stack>
            )}

            <Stack
               style={{
                  width: '100%',
                  height: `calc(100vh - ${
                     isMobile
                        ? addButtonConfig[pathname]?.showFor?.includes(user?.getUser.role)
                           ? '180px'
                           : '180px'
                        : '115px'
                  })`,
                  position: 'relative',
               }}
            >
               {children}
            </Stack>
         </Stack>

         {isMobile && (
            <SwipeableDrawer
               open={isMenuOpen}
               onOpen={handleMenuToggle}
               onClose={handleMenuToggle}
               anchor="left"
               sx={{
                  '& .MuiDrawer-paper': {
                     background:
                        'radial-gradient(59.93% 52.07% at 94.73% 11.33%, rgba(62, 189, 229, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(97.2% 117.34% at 4.49% 37.13%, rgba(0, 41, 255, 0.20) 0%, rgba(112, 160, 96, 0.00) 97.23%), radial-gradient(73.02% 51.32% at 88.09% 79.24%, rgba(13, 153, 255, 0.20) 0%, rgba(177, 222, 255, 0.00) 100%), #F4F5F6; ',
                  },
               }}
            >
               <Stack
                  sx={{
                     width: 300,
                     alignItems: 'center',
                     minHeight: '100vh',
                     height: '100%',
                     justifyContent: 'space-between',
                     padding: '2rem 1rem 1rem',
                  }}
               >
                  <Stack width="100%" alignItems="center">
                     <Logo />

                     <List sx={{ mt: 6, width: '100%' }}>
                        {navigationItems
                           .filter((item) => ROLE_PERMISSIONS[item.path].includes(role || 'ADMIN'))
                           .map((item) => renderMenuItem({ item, currentPage }))}
                     </List>
                  </Stack>

                  <List sx={{ mt: 4, width: '100%' }}>
                     {notificationItems.map((item) => renderMenuItem({ item, currentPage }))}
                  </List>
               </Stack>
            </SwipeableDrawer>
         )}
      </>
   );
};

export { Layout };
