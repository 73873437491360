import { useMediaQuery } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { MobileMediaQuery } from 'src/utils/constants';

export default function ScrollContainer({
   children,
   style,
   reducerMore,
}: {
   children: React.ReactNode;
   style?: React.CSSProperties;
   reducerMore?: string;
}) {
   const isMobile = useMediaQuery(MobileMediaQuery);

   return (
      <PerfectScrollbar
         style={{
            marginTop: '1rem',
            height: `calc(100vh - ${isMobile ? '300px' : '200px'} - ${reducerMore || '0px'})`,
            ...style,
            overflow: 'hidden',
         }}
      >
         {children}
      </PerfectScrollbar>
   );
}
