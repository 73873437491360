import { Grid, Stack, styled } from '@mui/material';

export const MenuContainer = styled(Stack)(({ theme }) => ({
   // theme.palette.primary.main,
   background:
      'radial-gradient(59.93% 52.07% at 94.73% 11.33%, rgba(62, 189, 229, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(97.2% 117.34% at 4.49% 37.13%, rgba(0, 41, 255, 0.20) 0%, rgba(112, 160, 96, 0.00) 97.23%), radial-gradient(73.02% 51.32% at 88.09% 79.24%, rgba(13, 153, 255, 0.20) 0%, rgba(177, 222, 255, 0.00) 100%), #F4F5F6; ',
   boxShadow: '2px 2px 4px 0px rgba(94, 81, 175, 0.30)',
   // boxShadow: "2px 2px 8px 0px #00000020",
   padding: '2rem 1rem',
   width: '256px',
   marginTop: '0',
   marginLeft: '0',
   height: 'calc(100vh - 2rem)',
   margin: '1rem 0 0 1rem',
   borderRadius: '1rem',
   alignItems: 'center',
   justifyContent: 'space-between',
}));

export const MenuItemStyled = styled(Grid)(({ theme }) => ({
   backgroundColor: '#fff',
   color: '#454754',
   padding: '10px 20px',
}));
