import { useSnackbar, VariantType, SnackbarKey } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseTwoTone from '@mui/icons-material/CloseTwoTone';

// EX: sendNotification({ msg: 'yourmessage', variant: 'success' })

export interface NotificationConfig {
   msg: string;
   variant?: VariantType;
   duration?: number;
}

type SendNotification = (config: NotificationConfig) => void;

const useNotification = (): [NotificationConfig, React.Dispatch<React.SetStateAction<NotificationConfig>>] => {
   const [conf, setConf] = useState<NotificationConfig>({
      msg: "",
   });
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();

   const action = (key: SnackbarKey) => (
      <Fragment>
         <IconButton
            onClick={() => {
               closeSnackbar(key);
            }}
         >
            <CloseTwoTone />
         </IconButton>
      </Fragment>
   );

   useEffect(() => {
      if (conf?.msg) {
         let variant: VariantType = 'info';

         if (conf.variant) {
            variant = conf.variant;
         }

         enqueueSnackbar(conf.msg, {
            variant,
            autoHideDuration: conf.duration || 3000,
            action,
         });
      }
   }, [conf]);

   return [conf, setConf];
};

export default useNotification;
